import React from 'react'
import Img from 'gatsby-image'

import usePageQuery from './../../pageQuery'

const InterGroup = () => {
  const data = usePageQuery()
  return (
    <section className='py-5'>
      <div className='container px-0'>
        <div className='row align-items-center'>
          <div className='col-12 col-lg-8'>
            <h3>Inter Group - Everything that you need in your bank</h3>
            <Img className='pb-3' fixed={data.interDtvm.fixed} alt='Inter DTVM' />
            <p className='mt-2'>Investment platform from Banco Inter, authorized by the Central Bank of Brazil, certified by the CVM and part of ANBIMA. Inter DTVM has products for fixed and variable investments, with great options for you to invest accordingly to your profile.</p>
            <Img className='pb-3' fixed={data.interSeguros.fixed} alt='Inter Seguros' />
            <p className='mt-2 mb-4'>We have partnerships with the best insurance companies in the Market, offering insurances for you and for your company.</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default InterGroup
