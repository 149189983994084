import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const SideBar = styled.aside`
  margin-top: 30px;

  @media ${device.desktopLG} {
    position: absolute;
    top: 3rem;
    left: 50%;
    right: 0;
    bottom: 35px;
    margin-top: 0;
    max-width: 320px;
    transform: translate(50%);
    width: 33.333%;
    z-index: 1;
  
    > div {
      top: 150px;
    }
  }

  @media ${device.desktopXL} {
    max-width: 380px;
  }

  @media (min-width: 1600px) {
    max-width: 480px;
  }

  .sidebar-box > div {
    padding-left: calc(8.33% + 12px);
    padding-right: calc(8.33% + 12px);
  }
`

export const ContentWrapper = styled.div`
  position: relative;
`
