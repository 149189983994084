import React, { memo } from 'react'
import { translate, InjectedTranslateProps } from 'react-i18next'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

type WorkWithUsProps = {
  url?: string;
  t: (value: string) => void;
};

function WorkWithUs ({ url, t }: WorkWithUsProps & InjectedTranslateProps) {
  const [ sendDatalayerEvent ] = useDataLayer()

  const handleLink = () => {
    sendDatalayerEvent({
      section: t('work_with_us.title') as unknown as string,
      section_name: t('work_with_us.wanna_join') as unknown as string,
      element_action: 'click button',
      element_name: t('work_with_us.see_oportunities') as unknown as string,
      redirect_url: url,
    })
    window.open(url, '_blank')
  }

  return (
    <div className='work-with-us sidebar-box bg-white pt-4 text-center'>
      <div className='col-12'>
        <h3 className='fs-17 pb-2'>{t('work_with_us.title')}</h3>
      </div>
      <div className='col-12 pt-2 pb-2'>
        <p className='text-gray--base fs-15'>{t('work_with_us.wanna_join')}</p>
      </div>
      <div className='col-12 pt-2 pb-4'>
        <a
          className='btn btn-link btn--block fs-14'
          title='See our opportunities'
          onClick={() => handleLink()}
        >
          {t('work_with_us.see_oportunities')}
        </a>
      </div>
    </div>
  )
}

WorkWithUs.defaultProps = {
  url:
    'https://bancointer.csod.com/ats/careersite/search.aspx?site=4&c=bancointer&source=sitebanco',
  t: () => Object,
}

export default translate('Components')(memo(WorkWithUs))
