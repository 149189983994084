import React from 'react'

import Layout from 'src/layouts/BaseLayout'
import Hero from './sections/header/_hero'
import Sidebar from './sections/sidebar/_sidebar'

import pageContext from './pageContext.json'

import { Wrapper } from './style'

const TheBankEN = () => {
  return (
    <Layout pageContext={pageContext}>
      <Wrapper>
        <Hero />
        <Sidebar />
      </Wrapper>
    </Layout>
  )
}

export default TheBankEN
