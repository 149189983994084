import React from 'react'

import SectionTopics from 'src/components/SectionTopics'

import hubColorEn from '../../../../../../static/Home/images/hub-color-en.png'

const BelievePartnership = () => {
  return (
    <section className='py-5'>
      <div className='container px-0'>
        <div className='row align-items-center'>
          <div className='col-12 col-lg-8'>
            <h3>We really believe in partnership</h3>
            <p>Banco Inter is the first 100% digital bank in Brazil and the only one to provide a digital checking account free of charge.</p>
            <p>We believe that the relationship between the customer and the bank can be much simpler, transparent and fair. We are making this transformation happen.</p>
            <p><strong>Partnership is only possible through trust</strong></p>
            <p>We are safe as a bank should be. We started our journey in 1994, and we never stop evolving:</p>
            <SectionTopics title='(R)Evolution' styles='fs-xl-30'>
              From Financing Institution to a full service bank, certified by the Central bank of Brazil<br />
              From regional to national<br />
              From offering only loans to multi banking services
            </SectionTopics>
            <div className='col-12 col-sm-10 offset-sm-1 col-lg-12 offset-lg-4 offset-xl-0'>
              <div className='col-12 col-lg-8'>
                <img className='pb-3 img-fluid' src={hubColorEn} alt='Hub Banco Inter' />
              </div>
            </div>
            <p className='mt-5'>And we will grow even more together with you!</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BelievePartnership
