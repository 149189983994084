import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {    
      interDtvm: imageSharp(fixed: {originalName: { regex: "/interdtvm/" }}) {
        fixed(width: 219, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
      interSeguros: imageSharp(fixed: {originalName: { regex: "/interseguros/" }}) {
        fixed(width: 276, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
