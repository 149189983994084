import React, { ReactNode } from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import Link from 'components/GatsbyLinkWrapper/index'

import './SectionTopics.scss'

type sectionTopicsProps = {
  title: string;
  children: ReactNode;
  size: string;
  url?: string;
  styles?: string;
}

function SectionTopics ({ title, children, size, url, styles }: sectionTopicsProps) {
  const [ sendDatalayerEvent ] = useDataLayer()

  const Capitalize = (title: string) => {
    return title.toUpperCase()
      .split(' ')
      .map((item: string) => {
        return item[0].toUpperCase() + item.slice(1)
      }).join(' ')
  }

  const boxClass: string = `boxed boxed--${size} boxed--orange boxed--dotted`

  const box = url !== ''
    ? (
      <Link
        to={url}
        className='text-gray--base hover-orange'
        onClick={() => {
          sendDatalayerEvent({
            section: 'dobra_02',
            element_action: 'click button',
            element_name: title,
            section_name: 'Fundos administrados',
            redirect_url: url,
          })
        }}
      >
        <div className='title-container'>
          <div
            className={boxClass}
          >
            <h3 className={styles}>{Capitalize(title)}</h3>
          </div>
        </div>
      </Link>
    )
    : (
      <div className='title-container'>
        <div className={boxClass}>
          <h3 className={styles}>{Capitalize(title)}</h3>
        </div>
      </div>
    )

  return (
    <div className='section-topics'>
      <div className='row'>
        <div className='col-12 col-md-5 col-xl-4'>
          {box}
        </div>
        <div className='d-flex align-items-center col-12 col-md-7 col-xl-8'>
          <div>{children}</div>
        </div>
      </div>
    </div>
  )
}

SectionTopics.defaultProps = {
  title: 'Opções',
  url: '',
  size: 'sm',
  children: <></>,
  styles: 'fs-20',
}

export default SectionTopics
