import React from 'react'
import { SideBar, ContentWrapper } from 'src/layouts/BaseLayout/style'

import WorkWithUs from 'src/components/WorkWithUs'
import BelievePartnership from '../believe-partnership/_believe-partnership'
import InterGroup from '../inter-group/_inter-group'

const Sidebar = () => {
    return (
      <ContentWrapper>
        <div className='container'>
          <BelievePartnership />
          <InterGroup />
          <SideBar className='col-12 col-lg-4 px-0 px-lg-3'>
            <div className='position-sticky'>
              <div className='position-sticky'>
                <WorkWithUs url='https://carreiras.inter.co/' />
              </div>
            </div>
          </SideBar>
        </div>
      </ContentWrapper>
    )
}

export default Sidebar
